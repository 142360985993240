import React, { useState, useEffect } from 'react';
import { Fade, Zoom } from "react-awesome-reveal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Logo from './imgs/gd.png';
import axios from 'axios';

function Soumissionpagenum() {
    const storedLang = localStorage.getItem("lang");
    const [lang, setLang] = useState(storedLang ? storedLang : "FR");

    // FETCH DATA
    const [pageData, setPageData] = useState({});

    let langPos;
    lang === "FR" ? (langPos = "1") : (langPos = "2");

    useEffect(() => {
        getPageData();
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [langPos]);

    const getPageData = async () => {
        const response = await axios.get("https://groupelitho.com/soumissions/" + langPos);
        setPageData(response.data);
    };
    // END FETCH DATA

    const sendEmail = async (e) => {
        e.preventDefault();

        // Prepare the email payload
        const payload = {
            api_key: "api-18AFE2CF094B48539EB6BD5AE3CDFE90", // Replace with your SMTP2GO API key
            to: ["info.montreal@graphiscan.com"], // Destination email(s)
            bcc: ["zemouri.ayoub@gmail.com"], // Optional BCC
            sender: "Site graphiscan <azemouri@graphiscan.com>", // Sender's email
            subject: "Message sur Graphiscan.com",
            text_body: `
Nom : ${e.target.nom.value}
Courriel : ${e.target.email.value}
Telephone : ${e.target.tel.value}
Entreprise : ${e.target.entreprise.value || "N/A"}
Ref : ${e.target.ref.value || "N/A"}

Message:
${e.target.message.value}
            `
        };

        try {
            const response = await axios.post("https://api.smtp2go.com/v3/email/send", payload);
            console.log("Email sent successfully:", response.data);
            e.target.reset(); // Clear the form after successful submission
        } catch (error) {
            console.error("Error sending email:", error);
        }
    };

    return (
        <div className="soumissionform">
            <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to="/">
                <div className="iconClose">
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </Link>

            <Fade top>
                <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to="/">
                    <section className="logo poplogo">
                        <img src={Logo} alt="" />
                    </section>
                </Link>
            </Fade>

            <Fade top>
                <div className="sectionTitle">
                    {pageData.title}
                </div>
            </Fade>

            <br /><br />

            <Zoom delay={200} duration={500}>
                <div className="contactform">
                    <form onSubmit={sendEmail}>
                        <input type="text" name="nom" placeholder={pageData.nameInput} required />
                        <input type="text" name="email" placeholder={pageData.emailInput} required />
                        <input type="text" name="tel" placeholder={pageData.phoneInput} required />
                        <input type="text" name="entreprise" placeholder={pageData.companyInput} />
                        <input type="text" name="ref" placeholder={pageData.refInput} />
                        <textarea name="message" placeholder={pageData.contentInput} required></textarea>

                        <button className="contactSend">{pageData.submitText}</button>
                    </form>
                    <br />
                </div>
            </Zoom>
        </div>
    );
}

export default Soumissionpagenum;
